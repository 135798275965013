<template>
  <!-- 城际专车结算明细 -->
  <div ref="IntercityBusTicketDetails" class="IntercityBusTicketDetails">
    <div ref="functionButtons" class="function-buttons"></div>
    <div ref="explain" class="explain">
      <header><i class="el-icon-warning"></i> 数据说明：</header>
      <ol>
        <li>订单金额=按下单时间导出列表，列表中【实付金额】之和</li>
        <li>
          退款金额=按退票（改签）时间导出列表，列表中的【退票（改签）金额】之和
        </li>
        <li>
          实际优惠金额=按支付时间导出列表中的【营销优惠金额】之和，减去，按退票(改签)时间导出列表中的【退(改)优惠金额】之和
        </li>
      </ol>
    </div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="queryForm"
        label-position="left"
        label-width="125px"
        class="demo-form-inline"
      >
        <el-form-item label="支付时间起：">
          <el-date-picker
            v-model="queryForm.wxTransactionTimeBegin"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            default-time="00:00:00"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="支付时间止：">
          <el-date-picker
            v-model="queryForm.wxTransactionTimeEnd"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            default-time="23:59:59"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="发车时间起：">
          <el-date-picker
            v-model="queryForm.departureDateTimeBegin"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            default-time="00:00:00"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="发车时间止：">
          <el-date-picker
            v-model="queryForm.departureDateTimeEnd"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            default-time="23:59:59"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="退票(改签)时间起：">
          <el-date-picker
            v-model="queryForm.refundTimeBegin"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            default-time="00:00:00"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="退票(改签)时间止：">
          <el-date-picker
            v-model="queryForm.refundTimeEnd"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            default-time="23:59:59"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="所属公司：">
          <companySelect
            :clearable="true"
            :company-tree="companyList"
            :valuename="valuename"
            :value="value"
            @getValue="getGroupId"
          ></companySelect>
        </el-form-item>
        <el-form-item label="司机姓名：">
          <el-select
            v-model="queryForm.driverIds"
            filterable
            remote
            multiple
            collapse-tags
            reserve-keyword
            placeholder="请输入司机"
            clearable
            :remote-method="queryDriver"
            @clear="queryForm.driverIds = []"
          >
            <el-option
              v-for="item in driversList"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车牌号：">
          <el-select
            v-model="queryForm.vehicleIds"
            filterable
            remote
            multiple
            collapse-tags
            reserve-keyword
            placeholder="请输入车牌号"
            clearable
            :remote-method="queryCph"
            @clear="queryForm.vehicleIds = []"
          >
            <el-option
              v-for="item in carNumber"
              :key="item.id"
              :label="item.licensePlateNumber"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="起点站：">
          <el-select
            v-model="queryForm.startStation"
            clearable
            placeholder="请选择起点站"
          >
            <el-option
              v-for="item in startSelect"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="终点站：">
          <el-select
            v-model="queryForm.endStation"
            clearable
            placeholder="请选择终点站"
          >
            <el-option
              v-for="item in endSelect"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryFun"
            >查询</el-button
          >
          <el-button type="primary" size="small" @click="exportFun"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :table-height="tableHeight"
      :col-class-type="true"
    ></Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="queryForm.pageSize"
        :current-page="queryForm.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { lastTime } from "@/tools/getTime.js";
import {
  intercityCarListAPI,
  getDriverListAllAPI,
  getCompanyTree,
  getVehicleListAllAPI,
  intercityCarListExpAPI,
  startStationAPI,
  endStationAPI,
} from "@/api/lib/api.js";
import companySelect from "@/components/treeSelect/companySelect.vue";
export default {
  components: {
    companySelect,
  },
  data() {
    return {
      tableData: [],
      titleName: [
        {
          title: "业务类型",
          props: "businessType",
          SpecialJudgment: (res) => {
            return res == 1 ? "城际专车" : "";
          },
        },
        {
          title: "公司",
          props: "companyName",
          width: 150,
        },
        {
          title: "订单编号",
          props: "orderId",
          width: 220,
        },
        {
          title: "票号",
          props: "orderChildId",
          width: 220,
        },
        {
          title: "下单时间",
          props: "createTime",
          width: 170,
        },
        {
          title: "支付时间",
          props: "wxTransactionTime",
          width: 170,
        },
        {
          title: "发车时间",
          props: "departureDateTime",
          width: 170,
        },
        {
          title: "乘客数量",
          props: "passengerCount",
        },
        {
          title: "购票应付金额",
          props: "payablePrices",
          width: 120,
        },
        {
          title: "营销优惠金额",
          props: "preferentialPrices",
          width: 120,
        },
        {
          title: "实付金额",
          props: "totalPrices",
        },
        {
          title: "线路",
          props: "lineName",
        },
        {
          title: "司机姓名",
          props: "driverName",
        },
        {
          title: "车牌号",
          props: "licensePlateNumber",
        },
        {
          title: "状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            let text = "";
            switch (res) {
              case 10:
                text = "待支付";
                break;
              case 20:
                text = "待配车";
                break;
              case 30:
                text = "已配车(待派单)";
                break;
              case 40:
                text = "已派单(待接驾)";
                break;
              case 50:
                text = "司机申请取消接驾";
                break;
              case 60:
                text = "已接驾";
                break;
              case 70:
                text = "已完成";
                break;
              case 80:
                text = "取消接驾";
                break;
              case 90:
                text = "用户已退票";
                break;
              case 100:
                text = "后台已退票";
                break;
              case 110:
                text = "订单取消";
                break;
              case 120:
                text = "已退票";
                break;
              case 130:
                text = "已改签";
            }
            return text;
          },
        },
        {
          title: "退票(改签)金额",
          props: "refundFee",
          width: 120,
        },
        {
          title: "退票(改签)手续费",
          props: "poundage",
          width: 130,
        },
        {
          title: "退(改)优惠金额",
          props: "refundTicketPreferentialPrices",
          width: 130,
          SpecialJudgment: (res) => {
            return res ? res : "";
          },
        },
        {
          title: "退票(改签)时间",
          props: "refundTime",
          width: 170,
        },
      ],
      value: null,
      valuename: null,
      total: 0,
      startSelect: [],
      endSelect: [],
      tableHeight: 0,
      exportForm: {},
      queryForm: {
        wxTransactionTimeBegin: "",
        wxTransactionTimeEnd: "",
        refundTimeBegin: "",
        refundTimeEnd: "",
        departureDateTimeBegin: "",
        departureDateTimeEnd: "",
        driverIds: "",
        vehicleIds: "",
        companyIds: "",
        pageSize: 10,
        currentPage: 1,
      },
      companyList: [],
      driversList: [],
      carNumber: [],
      flag: false,
    };
  },
  mounted() {
    this.queryForm.wxTransactionTimeBegin = lastTime(new Date()) + " 00:00:00";
    let day = new Date();
    this.queryForm.wxTransactionTimeEnd =
      day.getFullYear() +
      "-" +
      (day.getMonth() + 1) +
      "-" +
      day.getDate() +
      " 23:59:59";
    this.queryFun();
    this.getCompanyList();
    this.selectdataAPIFun();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  methods: {
    queryFun() {
      this.queryForm.pageSize = 10;
      this.queryForm.currentPage = 1;
      this.renderData();
    },
    computeHeight() {
      const wholeHeight = this.$refs.IntercityBusTicketDetails.clientHeight;
      const buttonsHeight = this.$refs.functionButtons.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      const explain = this.$refs.explain.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        explain -
        10 -
        36;
    },
    // 分页页数发生变化
    onCurrentChange(val) {
      this.queryForm.currentPage = val;
      this.renderData();
    },
    // 分页change方法
    onSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.currentPage = 1;
      this.renderData();
    },
    exportFun() {
      this.exportForm = this.deepClone(this.queryForm);
      this.exportForm.currentPage = "";
      this.exportForm.pageSize = "";
      intercityCarListExpAPI(this.exportForm).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName =
          "城际专车结算明细订单 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    renderData() {
      intercityCarListAPI(this.queryForm).then((res) => {
        if (res.code == "SUCCESS") {
          console.log(res.data.list);
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    queryDriver(query) {
      this.driversList = [];
      if (query !== "") {
        setTimeout(() => {
          const data = {
            userName: query,
            driveType: 0,
          };
          getDriverListAllAPI(data).then((res) => {
            if (res.code === "SUCCESS") {
              this.driversList = res.data;
            }
          });
        }, 200);
      } else {
        this.driversList = [];
      }
    },
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    getGroupId(v) {
      if (v == null) {
        this.queryForm.companyIds = [];
      } else {
        this.queryForm.companyIds = [v];
      }
    },
    // 获取车牌号
    queryCph(query) {
      this.carNumber = [];
      if (query !== "") {
        setTimeout(() => {
          getVehicleListAllAPI().then((res) => {
            if (res.code === "SUCCESS") {
              this.carNumber = res.data.filter((i) => {
                return (
                  i.licensePlateNumber.indexOf(query) > -1 &&
                  i.operationMode.length == 1 &&
                  i.operationMode[0] == 1
                );
              });
            }
          });
        }, 200);
      } else {
        this.carNumber = [];
      }
    },
    // 起终点下拉数据获取
    selectdataAPIFun() {
      startStationAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.startSelect = [];
          res.data.map((item, index) => {
            this.startSelect.push({ value: index, label: item });
          });
        }
      });
      endStationAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.endSelect = [];
          res.data.map((item, index) => {
            this.endSelect.push({ value: index, label: item });
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.IntercityBusTicketDetails {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 4px 20px;
  box-sizing: border-box;
  background: #ffffff;
  .explain {
    color: #666666;
    font-size: 14px;
    background-color: #e4ecff;
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
    header {
      font-weight: bold;
      .el-icon-warning {
        color: #336ffe;
        font-size: 17px;
      }
    }
    ol {
      margin-bottom: 0;
      li {
        margin-bottom: 8px;
      }
    }
  }
}
.Table {
  margin-top: 16px;
}
</style>
