var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "IntercityBusTicketDetails",
      staticClass: "IntercityBusTicketDetails",
    },
    [
      _c("div", { ref: "functionButtons", staticClass: "function-buttons" }),
      _c("div", { ref: "explain", staticClass: "explain" }, [
        _vm._m(0),
        _vm._m(1),
      ]),
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                model: _vm.queryForm,
                "label-position": "left",
                "label-width": "125px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "支付时间起：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      "default-time": "00:00:00",
                      placeholder: "选择时间",
                    },
                    model: {
                      value: _vm.queryForm.wxTransactionTimeBegin,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "wxTransactionTimeBegin", $$v)
                      },
                      expression: "queryForm.wxTransactionTimeBegin",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "支付时间止：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      "default-time": "23:59:59",
                      placeholder: "选择时间",
                    },
                    model: {
                      value: _vm.queryForm.wxTransactionTimeEnd,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "wxTransactionTimeEnd", $$v)
                      },
                      expression: "queryForm.wxTransactionTimeEnd",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发车时间起：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      "default-time": "00:00:00",
                      placeholder: "选择时间",
                    },
                    model: {
                      value: _vm.queryForm.departureDateTimeBegin,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "departureDateTimeBegin", $$v)
                      },
                      expression: "queryForm.departureDateTimeBegin",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发车时间止：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      "default-time": "23:59:59",
                      placeholder: "选择时间",
                    },
                    model: {
                      value: _vm.queryForm.departureDateTimeEnd,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "departureDateTimeEnd", $$v)
                      },
                      expression: "queryForm.departureDateTimeEnd",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "退票(改签)时间起：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      "default-time": "00:00:00",
                      placeholder: "选择时间",
                    },
                    model: {
                      value: _vm.queryForm.refundTimeBegin,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "refundTimeBegin", $$v)
                      },
                      expression: "queryForm.refundTimeBegin",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "退票(改签)时间止：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      "default-time": "23:59:59",
                      placeholder: "选择时间",
                    },
                    model: {
                      value: _vm.queryForm.refundTimeEnd,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "refundTimeEnd", $$v)
                      },
                      expression: "queryForm.refundTimeEnd",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属公司：" } },
                [
                  _c("companySelect", {
                    attrs: {
                      clearable: true,
                      "company-tree": _vm.companyList,
                      valuename: _vm.valuename,
                      value: _vm.value,
                    },
                    on: { getValue: _vm.getGroupId },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "司机姓名：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        remote: "",
                        multiple: "",
                        "collapse-tags": "",
                        "reserve-keyword": "",
                        placeholder: "请输入司机",
                        clearable: "",
                        "remote-method": _vm.queryDriver,
                      },
                      on: {
                        clear: function ($event) {
                          _vm.queryForm.driverIds = []
                        },
                      },
                      model: {
                        value: _vm.queryForm.driverIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "driverIds", $$v)
                        },
                        expression: "queryForm.driverIds",
                      },
                    },
                    _vm._l(_vm.driversList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.userName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车牌号：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        remote: "",
                        multiple: "",
                        "collapse-tags": "",
                        "reserve-keyword": "",
                        placeholder: "请输入车牌号",
                        clearable: "",
                        "remote-method": _vm.queryCph,
                      },
                      on: {
                        clear: function ($event) {
                          _vm.queryForm.vehicleIds = []
                        },
                      },
                      model: {
                        value: _vm.queryForm.vehicleIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "vehicleIds", $$v)
                        },
                        expression: "queryForm.vehicleIds",
                      },
                    },
                    _vm._l(_vm.carNumber, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.licensePlateNumber,
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "起点站：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择起点站" },
                      model: {
                        value: _vm.queryForm.startStation,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "startStation", $$v)
                        },
                        expression: "queryForm.startStation",
                      },
                    },
                    _vm._l(_vm.startSelect, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "终点站：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择终点站" },
                      model: {
                        value: _vm.queryForm.endStation,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "endStation", $$v)
                        },
                        expression: "queryForm.endStation",
                      },
                    },
                    _vm._l(_vm.endSelect, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.queryFun },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.exportFun },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Table", {
        attrs: {
          "table-data": _vm.tableData,
          "title-name": _vm.titleName,
          "table-height": _vm.tableHeight,
          "col-class-type": true,
        },
      }),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.queryForm.pageSize,
              "current-page": _vm.queryForm.currentPage,
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("i", { staticClass: "el-icon-warning" }),
      _vm._v(" 数据说明："),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [_vm._v("订单金额=按下单时间导出列表，列表中【实付金额】之和")]),
      _c("li", [
        _vm._v(
          " 退款金额=按退票（改签）时间导出列表，列表中的【退票（改签）金额】之和 "
        ),
      ]),
      _c("li", [
        _vm._v(
          " 实际优惠金额=按支付时间导出列表中的【营销优惠金额】之和，减去，按退票(改签)时间导出列表中的【退(改)优惠金额】之和 "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }